import React from "react";
import styled from 'styled-components'
import PropTypes from 'prop-types';

const Button = styled.button`
  display: inline-block;
  border: none;
  padding: 0.8rem 3rem;
  margin: 0;
  text-decoration: none;
  background: #40b3e5;
  color: #ffffff;
  font-family: MontBold, sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,
  transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 25px;
  ${({hideOnMobile}) => hideOnMobile && `
    @media (max-width: 768px) {
      display: none;
    }
  `}

  :hover {
    background: #3cc1c0;
  }

  :active {
    transform: scale(0.99);
  }
  
  a {
    color: #fff;
    text-decoration: none;
  }
`;

const CallToAction = ({children, hideOnMobile = false}) => {
  return (
    <Button hideOnMobile={hideOnMobile}>
      <a href="mailto:leg.naprapat@telia.com?subject=Jag%20vill%20boka%20tid">
        {children}
      </a>
    </Button>
  )
}

CallToAction.propTypes = {
  children: PropTypes.string,
  hideOnMobile: PropTypes.bool,
}

export default CallToAction;