import React from "react";
import Hero from "./components/Hero";
import Information from "./components/Information";
import FindUs from './components/FindUs'
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Hero />
      <Information />
      <FindUs />
      <Footer />
    </div>
  );
}

export default App;
