import React from "react";
import backgroundImage from '../assets/heroBackground.jpeg'
import { ReactComponent as Logo } from '../assets/logoColor.svg';
import styled from 'styled-components'
import CallToAction from './CallToAction'

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  color: #222222;
  @media (max-width: 768px) {
    height: auto;
  }
`;

const Background = styled.div`
  height: 100%;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  z-index: -1;
  clip-path: polygon(35% 0%,100% 0%,100% 100%,65% 100%);

  @media (max-width: 768px) {
    background-position: left;
    clip-path: polygon(26% 0%,100% 0%,100% 100%,90% 100%);
  }
`;

const Navbar = styled.div`
  width: 100%;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  svg {
    width: 65px;
    height: 65px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  @media (max-width: 768px) {
    padding: 180px 0 100px;
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div`
  padding: 0 13%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 0 10%;
    max-width: 90%;
  }
  h1 {
    font-size: 48px;
    margin-bottom: 18px;
    @media (max-width: 768px) {
      font-size: 38px;
    }
  }
  h4 {
    font-size: 28px;
    font-family: MontRegular, sans-serif;
    margin-bottom: 18px;
    @media (max-width: 768px) {
      font-size: 20px;
      max-width: 75%;
    }
  }
`;

const Hero = () => {
  return (
  <Wrapper className="hero-wrapper">
    <Background />
    <Navbar>
      <Logo />
      <CallToAction hideOnMobile={true}>
        BOKA NU
      </CallToAction>
    </Navbar>
    <Grid>
      <Content>
        <h1>Låt inte smärtan komma ivägen</h1>
        <h4>Välkommen till Johan Andersson Leg. Naprapat baserad i Göteborg</h4>
        <div>
          <CallToAction>
            BOKA TID
          </CallToAction>
        </div>
      </Content>
    </Grid>
  </Wrapper>
  );
};

export default Hero;
