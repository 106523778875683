import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-left: calc(13% / 2); 
  padding-right: calc(13% / 2);
  padding-top: 100px;
  padding-bottom: 140px;
  background: rgb(64,179,229);
  background: linear-gradient(140deg, rgba(64,179,229,1) 0%, rgba(60,193,192,1) 100%);
  color: white;
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  
  h2 {
    font-family: MontBold, sans-serif;
    font-size: 48px;
    @media (max-width: 768px) {
      font-size: 38px;
    }
  }
  
  p {
    font-family: MontRegular, sans-serif;
    font-size: 22px;
    line-height: 2.5rem;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 1.5rem;
    }
  }
`;

const Intro = styled.div`
  margin-bottom: 80px;
`;

const Breakdown = styled.div`
  section {
    display: grid;
    grid-column-gap: 90px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    div {
      max-width: 90%;
      margin-top: 60px;
      @media (max-width: 768px) {
        margin-top: 40px;
      }
    }
    h4 {
      font-size: 28px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    p {
      margin: 0;
    }
  }
`;

const Information = () => {
    return (
      <Wrapper>
          <Intro>
            <h2>Med fokus på dig</h2>
            <p>Lider du av kroniska och akuta besvär i muskler och leder? Då har du kommit rätt. Jag behandlar och förebygger olika funktionsnedsättningar eller smärttillstånd som te.x nackspärr, olika former av huvudvärk, yrsel, domningar i armar, ryggskott, ischias samt erbjuder individuellt anpassade träningsprogram. Jag behandlar även olika idrotts- och motionsrelaterade skador och överansträningar.</p>
          </Intro>
            <Breakdown>
                <h2>Behandlings<wbr/>metoder</h2>
                <section>
                    <div>
                        <h4>Manipulation</h4>
                        <p>I syfte att normalisera funktion i led med ett tryck.</p>
                    </div>
                    <div>
                        <h4>Mobilisering</h4>
                        <p>I syfte att normlisera led utan tryck.</p>
                    </div>
                    <div>
                        <h4>Muskeltöjning</h4>
                        <p>I syfte att öka rörlighet i muskulatur och led.</p>
                    </div>
                    <div>
                    <h4>Massage</h4>
                    <p>I syfte att mjuka upp spända muskler och få dom att slappna av.</p>
                </div>
                    <div>
                    <h4>Elektromedicin</h4>
                    <p>I syfte att öka cirkulationen i muskulaturen och få den att slappna av och minska smärta.</p>
                </div>
                    <div>
                    <h4>Rehabträning</h4>
                    <p>Behandla olika funktionsnedsättningar eller smärttillstånd med individuellt anpassade träningsprogram.</p>
                </div>

                </section>
            </Breakdown>
        </Wrapper>
    )
};

export default Information;