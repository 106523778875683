import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../assets/logoWhite.svg';

const Wrapper = styled.div`
  padding-left: calc(13% / 2);
  padding-right: calc(13% / 2);
  padding-top: 100px;
  padding-bottom: 80px;
  background: rgb(64,179,229);
  background: linear-gradient(180deg, rgba(64,179,229,1) 0%, rgba(60,193,192,1) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    width: 65px;
    height: 65px;
  }

  p, a {
    font-family: MontRegular, sans-serif;
    font-size: 16px;
    color: white;
    text-align: center;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    padding-top: 30px;
    padding-bottom: 60px;
    p, a {
      font-size: 12px;
    }
  }
  @media (min-width: 769px) {
    br {
      display: none;
    }
  }
`;

const Footer = () => {
    return (
      <Wrapper>
          <Logo />
          <p>© Johan Andersson leg. Naprapat.<br /> Ring och boka tid: <a href="tel:+46705837019">0705-837019</a>, <a href="tel:+4631181117">031-181117</a>.<br /> Sida av <a href="http://wearekidpilot.com/">wearekidpilot.com</a> </p>
      </Wrapper>
    );
}

export default Footer;