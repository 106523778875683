import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  padding-left: calc(13% / 2); 
  padding-right: calc(13% / 2);
  padding-top: 100px;
  padding-bottom: 80px;

  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 60px;
  }
  
  h2 {
    font-family: MontBold, sans-serif;
    font-size: 48px;
    margin-bottom: 16px;
    @media (max-width: 768px) {
      font-size: 38px;
    }
  }
  
  h4 {
      font-family: MontRegular, sans-serif;
      font-size: 28px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  
  p, a {
    font-family: MontRegular, sans-serif;
    font-size: 22px;
    line-height: 2.5rem;
    color: #222222;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 1.5rem;
    }
  }
  
  em {
    font-style: normal;
    font-family: MontBold, sans-serif;
  }
`;

const Adress = styled.div`
  margin-bottom: 26px;
`;

const Info = styled.div`
  display: grid;
  grid-column-gap: 90px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    margin-top: 20px;
    grid-template-columns: 1fr;
  }
`;

const FindUs = () => {
    return (
        <Wrapper>
            <section>
                <h2>Hitta hit</h2>
                <Adress>
                    <h4>Danska Vägen 84B</h4>
                    <h4>416 59 Göteborg</h4>
                </Adress>
                <a href="https://goo.gl/maps/dBhmSw5vLhqAhian8">Vägbeskrivning</a>
            </section>
            <Info>
                <section>
                    <p><em>Åker du spårvagn</em> är närmast hållplats Redbergsplatsen, ca 300m promenad upp på danska vägen till mottagningen. Buss 60 stannar vid Nobelplatsen som är något närmare.</p>
                    <a href="https://www.vasttrafik.se/">Västtrafik.se</a>
                </section>
                <section>
                    <p><em>Om du kör bil</em> parkerar du enklast någonstans utmed Danska Vägen.</p>
                </section>
            </Info>
        </Wrapper>
    )
}

export default FindUs;